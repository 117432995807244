@import "../../../styles/variables.scss";

.dg-page-title {
    padding-bottom: 20px;
    margin-bottom: 30px;

    .dg-container {
        
        .text {
            border-bottom: 1px solid $gray-300;
            p, h1, h3 {
                max-width: 600px;
            }
            
        }
    }
}