.dg-success {
    width: 100vw;
    height: 100vh;
    background-color: #39b54a;
    padding: 0 30px;
    padding-top: 30%;
    text-align: center;
    color: white;

    img {
        width: 50%;
    }

    h1 {
        margin-bottom: 20px;
        line-height: 60px;
        font-size: 40px;

        .amount {
            background-color: white;
            color: #39b54a;
            padding: 2px 5px;
            border-radius: 2px;
        }
    }

    .dg-logo {
        position: fixed;
        bottom: 0px;
        border-top: 8px solid #ff8f1c;
        background-color: #333f50;
        width: 100vw;
        padding: 15px;
        margin: 0 -30px;

        p {
            margin-bottom: 5px;
        }

        img {
            height: 20px;
        }
    }
}