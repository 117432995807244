@import "../../../styles/variables.scss";

.dg-start-button {
    color: $brand;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    background-color: lighten($brand, 40);
    border: none;
    border-bottom: 2px solid $brand;
    display: inline-block;
    margin: 15px 0;

    .flex {
        display: flex;
        align-items: center;
    }

    p {
        margin-bottom: 0px;
        line-height: 1;
        margin-right: 5px;
    }

    &:hover {
        background-color: darken($brand, 10);
        color: white;
    }

    &.loading {
        p {
            opacity: 0.7;
        }

        .material-icons {
            animation-name: spin;
            animation-duration: 4000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear; 
        }
    }
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}