.dg-error {
    width: 100%;
    height: calc(100vh - 120px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    
    h1 {
        color: white;
        font-size: 24px;
    }

    p {
        color: white;
        opacity: 0.8;
        text-align: center;
    }
}