

/* Colours */

$brand: #FF8F1C;
$orange: #FF8F1C;
$primary: $orange;
$text-dark: #333;
$text-light: #fff;
$heading-color: #444;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

/* Sizes */

$font-size-base: 1.05rem;
$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.2 !default;
$h4-font-size:                $font-size-base !default;
$font-size-lg:                $font-size-base !default;
$font-size-sm:                $font-size-base !default;

/* Border radius */

$border-radius:               0px !default;
$border-radius-lg:            0px !default;
$border-radius-sm:            0px !default;

@import "~bootstrap/scss/bootstrap";