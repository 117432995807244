@import "../../../styles/variables.scss";

.dg-page {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: $text-dark;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    background-color: $brand;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    p {
        font-size: 16px;
        line-height: 1.4;
        max-width: 600px;
        margin-bottom: 8px;

        .material-icons {
            line-height: 1.1rem;
        }

        &.small {
            font-size: 14px;
        }

    }

    a {
        color: $brand;
        font-weight: bold;
    }

    h1 {
        font-weight: 600;
        color: $heading-color;
        margin-bottom: 10px;
        z-index: 1;
    }

    h3 {
        color: $text-muted;
    }

    h4 {
        color: $brand;
        font-weight: bold;
        margin-bottom: 20px;
        font-size: 16px;
    }

    .intro {
        color: $gray-800;
        font-size: $font-size-base * 1.15;
        max-width: 700px;
        margin-bottom: 40px;
    }

    .emphasis {
        color: $brand;
    }

    .alert {
        border-width: 0px;
        border-bottom-width: 1px;
        margin-bottom: 25px;

        &.alert-danger {
            border-color: #721c24;
        }
    }

    
}

.dg-page::after {
    clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 50% 100%, 0% 60%);
    background-color: white;
    opacity: 0.15;
    width: 100vw;
    height: 40vh;
    position: absolute;
    top: 0px;
    content: '';
    left: 0px;
    z-index: 0;
}