@import "../../../styles/variables.scss";

.dg-header {
    margin-bottom: 60px;
    background-color: lighten($brand, 40);

    .logo {
        height: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .title {
        font-weight: normal;
        font-size: 36px;
        color: $text-muted;
        float: right;
        margin-top: 45px;
    }
}