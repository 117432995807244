@import "../styles/variables.scss";

.title {
    text-align: center;
    min-height: 35vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h3 {
        font-weight: 400;
        font-size: 16px;
        color: white;
        opacity: 0.8;
        margin-bottom: 5px;
    }

    h1.charity-name {
        font-weight: 700;
        font-size: 32px;
        line-height: 1;
        color: white;
    }

    .text {
        max-width: 400px;
    }

    .description {
        font-size: 14px;
        font-weight: 400;
        margin-top: 50px;
        color: $gray-700;
    }
}

.dg-give.row {
    display:flex;
    align-items:center;
    justify-content: flex-start;
    flex-direction:column;
    flex-grow: 5;
    margin: 0;

    .right {
        width: 100%;
        height: 100%;
        margin-bottom: 40px;

        .tab-content {
            height: 100%;
        }

        .tab-pane {
            text-align: center;
            max-width: 500px;
            background-color: white;
            padding: 30px 20px;
            width: 95%;
            border-radius: 16px;
            margin: auto;
            z-index: 20;
            position: relative;
            height: 100%;
            border-top: 1px solid $gray-300;

            .amounts {
                display: grid;
                grid-template-columns: 30% 30% 30%;
                grid-template-rows: 70px 70px;
                column-gap: 5%;
                row-gap: 15px;
                button {
                    background-color: white;
                    outline: none;
                    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
                    border: none;
                    width: 100%;
                    display: inline-block;
                    text-align: center;
                    color: $gray-700;
                    font-size: 20px;
                    padding: 20px 15px;
                    transition-duration: 0.3s;
                    font-weight: 500;
                    height: 70px;
                    margin: 0;

                    &.selected {
                        font-weight: 700;
                        color: $brand;
                        box-shadow: 0 1px 10px $brand;
                    }
        
                    b {
                        color: black;
                        transition-duration: 0.3s;
                    }
        
                    &.active {
                        border-color: $brand;
                    }
        
                    &:hover {
                        box-shadow: 0 3px 15px rgba(0,0,0,0.2);
                        transition-duration: 0.3s;

                        &.selected {
                            box-shadow: 0 3px 20px $brand;
                        }
        
                        b {
                            color: $brand;
                            transition-duration: 0.3s;
                        }
                    }
                }

                &.custom {
                    display: block;
                    font-size: 4em;

                    span {
                        font-size: 0.5em;
                        color: $gray-600;
                        margin-right: 5px;
                    }

                    input {
                        border: none;
                        border-bottom: 2px solid $gray-200;
                        width: 80%;

                        &:active, &:focus {
                            border-bottom-color: $brand;
                            outline: none;
                        }
                    }

                    button {
                        width: 100%;
                        height: 50px;
                        box-shadow: none;
                        padding: 0;
                        margin: 0px;
                        box-shadow: 0 1px 10px $brand;
                        background-color: $brand;
                        color: white;
                    }

                    
                }
            }
        }

        .gift-aid {
            font-size: 0.8em;
            color: $gray-700;
        }

        h5 {
            color: $gray-500;
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: 8px;
        }

        p {
            color: $gray-800;
        }
    }
    

    .donate-with {
        font-size: 0.8em;
        color: $gray-600;
        margin-top: 30px;

        img {
            width: 250px;
            margin-top: 10px;
        }
    }

    .assurance {
        background-color: lighten($brand, 40);
        border-left: 2px solid $brand;
        padding: 10px;
        max-width: 600px;
        margin: 20px 0;

        p {
            font-size: 0.8em;
            margin-bottom: 0;
        }
    }

    button {
        height: 40px;
        background-color: $brand;
        outline: none;
        box-shadow: 0 1px 3px rgba(0,0,0,0.2);
        border: none;
        border-radius: 5px;
        color: white;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 8px;
        padding: 0 10px;
        margin-top: 30px;
        margin-right: 15px;

        &:disabled {
            opacity: 0.5;
        }

        &.muted {
            box-shadow: none;
            background-color: white;
            font-weight: 500;
            color: $gray-600;
            font-size: 16px;
        }
    }

    .dg-input {
        max-width: 650px;
        border: 1px solid $gray-300;
        border-radius: 5px;
        padding: 15px;

        .small {
            border-bottom: 1px solid $gray-200;
            padding-bottom: 20px;
        }

        .form-label, .form-control, select {
            font-size: 14px;
        }

        &.payment {
            padding: 20px 15px;
            margin-bottom: 20px;

            button {
                width: 100%;
                margin-top: 15px;
                font-size: 15px;
                height: 35px;
            }

            .method-blurb {
                margin: 0 0 15px 0;
                font-size: 14px;
                color: $gray-700;
            }
        }
    }

    .balloons {
        height: 250px;
        position: fixed;
        top: 100%;
        animation-name: balloons;
        animation-duration: 5s;
        transform: rotate(0deg);
        z-index: 50;
    }

    .icon-tick {
        height: 50px;
        margin-bottom: 20px;
    }

    .alert {
        font-size: 13px;
        padding: 5px;
    }
}

.dg-loading {
    margin: 0;
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: #ffe6c1;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        color: #ff8f1c;
        font-weight: bold;
        text-align: center;
        font-size: 1.5em;
        max-width: unset;
    }
}

@keyframes balloons {
    from {top: 100%; transform: rotate(0deg)}
    to {top: -250px; transform: rotate(10deg)}
}

@media(max-width: 768px) {
    .dg-give {
        .right {
            .tab-pane {
                padding: 30px 20px;
            }
        }
    }
}