@import "../../../styles/variables.scss";

.dg-footer {
    background-color: $gray-200;
    border-top: 1px solid $gray-300;
    width: 100%;
    z-index: 25;
    padding: 0 !important;

    .row {
        margin: 0;
    }

    .logos {
        padding: 10px 5px;
    }

    .givesafe {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        img {
            height: 10px;
            margin-right: 5px;
        }
    
        .org {
            background-color: #39b54a;
            padding: 3px 5px;
            color: white;
            font-weight: 700;
            display: flex;
            align-items: center;
            border-radius: 5px;
            img {
                height: 12px;
                margin-right: 3px;
            }
            p {
                margin-bottom: 0px;
                font-size: 12px;
                display: inline;
            }
        }
    }

    .copyright {
        text-align: right;
        img {
            height: 20px;
        }
    }

    .cookies {
        text-align: center;
        padding: 0 !important;
        p {
            font-size: 12px;
            color: white;
            background-color: rgba(0,0,0,0.5);
            padding: 10px 5px;
            margin: auto;
        }
    }
    

}