@import "../../../styles/variables.scss";

.declaration {
    font-size: 13px;
    border: 1px solid $gray-200;
    padding: 10px;
    margin-bottom: 30px;
}

.dg-form {
    text-align: left;

    label {
        color: $gray-700;
        font-size: 12px;
        margin-bottom: 2px;
        display: block;
    }
    
    select {
        width: 100%;
    }

    input {
        border: 1px solid $gray-300;
        border-radius: 5px;
        padding: 5px;
        width: 100%;
        color: $gray-700;

        &.invalid {
            border-color: rgba(175, 18, 18, 0.4);
            & ~ .validation {
                color: rgb(175, 18, 18);
            }
        }

        &:active, &:focus {
            border-color: lighten($brand, 25);
            outline: none;
            box-shadow: 0px 0px 0px 3px lighten($brand, 35);
        }
    }

    .validation {
        color: $gray-700;
        font-size: 12px;
        margin-top: 2px;
    }

    &.name {
        display: grid;
        grid-template-columns: 15% 37.5% 37.5%;
        grid-column-gap: 5%;
        column-gap: 5%;
    }

    &.address1 {
        display: grid;
        grid-template-columns: 47.5% 47.5% ;
        grid-column-gap: 5%;
        column-gap: 5%;
        border-top: 1px solid $gray-300;
        padding-top: 20px;
        margin-top: 20px;

        .form-group {
            margin-bottom: 5px;
        }
    }

    &.address2 {
        display: grid;
        grid-template-columns: 35% 35% 20% ;
        grid-column-gap: 5%;
        column-gap: 5%;
    }
}

@media(max-width: 768px) {
    .dg-form {
        &.row {
            margin-right: 0;
            margin-left: 0;
        }

        &.address1 {
            grid-template-columns: 100%;
            grid-template-rows: 83px 83px;
        }

        &.address2 {
            grid-template-columns: 47.5% 47.5%;
            grid-template-rows: 83px 83px;
        }
    }
}